.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nuevo-maximo {
  background-color: #36ff8a;
}

.operacion-posible {
  background-color: green;
  color: white;
}

.operacion-imposible {
  background-color: red;
  color: white;
}

.nuevo-valor {
  
  animation: pulse 3s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    background-color: #001f3f00;
  }
  100% {
    background-color: #36ff8a;
  }
}